import React from 'react'
import CardLineChart from './CardLineChart';
const ChartLine = ({ data, page }) => {
    const valueToGraphic = (weekly) => {
        const data = [], labels = [];
        if (weekly.length !== 0) {
            weekly.map(value => {
                if (value[page] !== undefined) {
                    data.push(Number(value[page].toFixed(1)));
                } else {
                    data.push(Number(0))
                }
                const date = new Date(value.date);
                const labelDate = `${date.getUTCHours()}:${date.getUTCMinutes()}, ${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
                labels.push(labelDate);
                return {
                    data,
                    labels
                };
            });
        }
        return {
            data,
            labels
        }
    }
    return (
        <>
            {
                <CardLineChart
                    data={valueToGraphic(data)}
                    page={page.toUpperCase()}
                />
            }
        </>
    )
}

export default ChartLine;
