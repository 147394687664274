import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardStats from 'components/Cards/CardStats';

import { getLastSingular, getMavgSingular } from 'actions/';
class OrpLast extends Component {
    componentDidMount() {
        this.props.getLastSingular("orp");
        this.props.getMavgSingular("orp");
    }

    render() {
        const { orpLast, orpMavg } = this.props;
        return (
            <CardStats
                statSubtitle="orp-last-value"
                statTitle={String(orpLast.orp) + " mV"}
                statTime="last-m-avg"
                statIconName="fas fa-certificate"
                statIconColor="bg-emerad-300"
                Last={orpLast.orp}
                Mavg={orpMavg}
                time={orpLast.date}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        orpLast: state.orpLast,
        orpMavg: state.orpMavg
    }
}
export default connect(mapStateToProps, { getLastSingular, getMavgSingular })(OrpLast);