import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/img/novumare.png';
import { connect } from 'react-redux';
import { login, isLogged } from 'actions/';
import { Translation } from 'react-i18next';

class Login extends Component {
  componentDidMount() {
    this.props.isLogged(this.props.history);
    this.onEnter();
  }

  state = {
    username: '',
    password: '',
    isLog: this.props.auth.isLog
  }
  onSubmit = (e) => {
    const { username, password } = this.state;
    this.props.login(username, password);
    e.preventDefault();
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  onEnter = () => {
    const login_component = document.getElementById('login_component');
    login_component.addEventListener('keyup', (e) => {
      const { username, password } = this.state;
      if (e.keyCode == 13) {
        this.props.login(username, password);
      }
    });
  }
  render() {
    return (
      <>
        <div className="container mx-auto px-4 h-full" id="login_component">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <img
                    src={logo}
                    className="ml-auto mr-auto"
                  />
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form>
                    <div className="relative w-full mb-3">
                      <Translation>
                        {
                          (t) =>
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              {t('email')}
                            </label>
                        }
                      </Translation>
                      <input
                        type="text"
                        name="username"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <Translation>
                        {
                          (t) =>
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              {t('password')}
                            </label>
                        }
                      </Translation>
                      <input
                        name="password"
                        type="password"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        onChange={this.onChange}
                      />
                    </div>
                    {/* <div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span className="ml-2 text-sm font-semibold text-gray-700">
                          Remember me
                      </span>
                      </label>
                    </div> */}
                    <div className="text-center mt-6">
                      <Translation>
                        {
                          (t) =>
                            <button
                              className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                              type="button"
                              onClick={this.onSubmit}
                            >
                              {t('signin')}
                            </button>
                        }
                      </Translation>
                    </div>
                  </form>
                </div>
              </div>
              <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                  <a
                    href="#pablo"
                    // onClick={this.handleSubmit}
                    className="text-gray-300"
                  >
                    <Translation>
                      {
                        (t) => <small>{t('forget-password')}?</small>
                      }
                    </Translation>
                  </a>
                </div>
                <div className="w-1/2 text-right">
                  <Link to="/auth/" className="text-gray-300">
                    <Translation>
                      {
                        (t) => <small>{t('create-new-account')}</small>
                      }
                    </Translation>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, { login, isLogged })(Login);
