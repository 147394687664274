import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardStats from 'components/Cards/CardStats';
import { getLastSingular, getMavgSingular } from 'actions/';
class CondLast extends Component {
    componentDidMount() {
        this.props.getLastSingular("ec");
        this.props.getMavgSingular("ec");
    }

    render() {
        const { ecMavg, ecLast } = this.props;
        return (
            <CardStats
                statSubtitle="ec-last-value"
                statTitle={String(ecLast.ec) + " mV"}
                statTime="last-m-avg"
                statIconName="fas fa-bolt"
                statIconColor="bg-yellow-300"
                Last={ecLast.ec}
                Mavg={ecMavg}
                time={ecLast.date}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ecLast: state.ecLast,
        ecMavg: state.ecMavg
    }
}

export default connect(mapStateToProps, { getLastSingular, getMavgSingular })(CondLast);