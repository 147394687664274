import {
    GETLASTFIVEDOSAGE,
    GETLASTCHEM1,
    GETLASTCHEM2,
    GETLASTCHEM3,
    GETMAVGCHEM1,
    GETMAVGCHEM2,
    GETMAVGCHEM3,
    GETALLWEEKLYDOSAGE,
    GETALLTWOWEEKLYDOSAGE,
    GETALLNODOSAGE,
    GETTWODATEDOSAGE,
    GETTWOWEEKCHEM1,
    GETTWOWEEKCHEM2,
    GETTWOWEEKCHEM3
} from './types';
import { API } from 'actions/api';
import axios from 'axios';
export const getLastThreeDosage = () => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/d/get/last/3?token=${token}`);
            dispatch({
                type: GETLASTFIVEDOSAGE,
                payload: response.data
            })
        }
    }
}
export const getLastChem = (chem) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/d/${chem}/last?token=${token}`);
            if (response.status === 200) {
                switch (chem) {
                    case "K1":
                        dispatch({
                            type: GETLASTCHEM1,
                            payload: response.data
                        })
                        break;
                    case "K2":
                        dispatch({
                            type: GETLASTCHEM2,
                            payload: response.data
                        })
                        break;
                    case "K3":
                        dispatch({
                            type: GETLASTCHEM3,
                            payload: response.data
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }
}
export const getMavgChem = (chem) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/d/${chem}/1mavg?token=${token}`);
            if (response.status === 200) {
                switch (chem) {
                    case "K1":
                        dispatch({
                            type: GETMAVGCHEM1,
                            payload: response.data
                        })
                        break;
                    case "K2":
                        dispatch({
                            type: GETMAVGCHEM2,
                            payload: response.data
                        })
                        break;
                    case "K3":
                        dispatch({
                            type: GETMAVGCHEM3,
                            payload: response.data
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }
}
export const getAllWeeklyDosage = (week, page) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        const nowDate = new Date();
        const pastDate = nowDate.getDate() - week;
        nowDate.setDate(pastDate);
        const day = String(nowDate.getDate());
        const year = String(nowDate.getFullYear());
        const month = String(nowDate.getMonth() + 1);
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/d/Get2Dates/${year}/${month}/${day}?token=${token}`);
            if (response.status === 200) {
                if (week === 7) {
                    dispatch({
                        type: GETALLWEEKLYDOSAGE,
                        payload: response.data
                    })
                } else if (week === 14) {
                    const responseAnalysis = await axios.get(`${API}/v1/a/Get2Dates/${year}/${month}/${day}?token=${token}`);
                    const res = [];
                    for (let i = 0; i < response.data.length; i++) {
                        res.push(
                            {
                                ...response.data[i],
                                score: responseAnalysis.data[i].score
                            }
                        )
                    }
                    switch (page) {
                        case "K1":
                            dispatch({
                                type: GETTWOWEEKCHEM1,
                                payload: res.reverse()
                            });
                            break;
                        case "K2":
                            dispatch({
                                type: GETTWOWEEKCHEM2,
                                payload: res.reverse()
                            });
                            break;
                        case "K3":
                            dispatch({
                                type: GETTWOWEEKCHEM3,
                                payload: res.reverse()
                            });
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }
}

export const getAllNoDosage = (no) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const responseDosage = await axios.get(`${API}/v1/d/get/${no}/all?token=${token}`);
            const responseScore = await axios.get(`${API}/v1/a/score/${no}/get?token=${token}`)
            const response = [{
                ...responseDosage.data[responseDosage.data.length - 1],
                score: responseScore.data[responseScore.data.length - 1].score
            }];
            dispatch({
                type: GETALLNODOSAGE,
                payload: response
            })
        }
    }
}
export const getTwoDateDosage = (date1, date2, page) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        const day1 = String(date1.getDate());
        const year1 = String(date1.getFullYear());
        const month1 = String(date1.getMonth() + 1);
        const day2 = String(date2.getDate());
        const year2 = String(date2.getFullYear());
        const month2 = String(date2.getMonth() + 1);
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/d/Get2Dates/${year1}/${month1}/${day1}/${year2}/${month2}/${day2}?token=${token}`);
            const resScore = await axios.get(`${API}/v1/a/Get2Dates/${year1}/${month1}/${day1}/${year2}/${month2}/${day2}?token=${token}`);
            const res = [];
            for (let i = 0; i < response.data.length; i++) {
                res.push(
                    {
                        ...response.data[i],
                        score: resScore.data[i].score
                    }
                )
            }
            console.log(page, res)
            switch (page) {
                case "dosages":
                    dispatch({
                        type: GETTWODATEDOSAGE,
                        payload: res.reverse()
                    })
                    break;
                case "K1":
                    dispatch({
                        type: GETTWOWEEKCHEM1,
                        payload: res.reverse()
                    });
                    break;
                case "K2":
                    dispatch({
                        type: GETTWOWEEKCHEM2,
                        payload: res.reverse()
                    });
                    break;
                case "K3":
                    dispatch({
                        type: GETTWOWEEKCHEM3,
                        payload: res.reverse()
                    });
                    break;
                default:
                    break;
            }
        }
    }
}