import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLogged } from 'actions/';

import AllAnalysis from './AllAnalysis';

class AllPage extends Component {
    componentDidMount() {
        this.props.isLogged(this.props.history);
    }
    render() {
        return (
            <>
                <AllAnalysis />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps, { isLogged })(AllPage);