// auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

//Perf (Score)
export const GETLASTPERF = 'GET_LAST_PERF';
export const GETMAVGPERF = 'GET_MAVG_PERF';

// pH
export const GETALLPH = 'GET_ALL_PH';
export const GETLASTPH = 'GET_LAST_PH';
export const GETMAVGPH = 'GET_MAVG_PH';

// Temp
export const GETALLTEMP = 'GET_ALL_TEMP';
export const GETLASTTEMP = 'GET_LAST_TEMP';
export const GETMAVGTEMP = 'GET_MAVG_TEMP';

//Cond (ec)
export const GETALLEC = 'GET_ALL_EC';
export const GETLASTEC = 'GET_LAST_EC';
export const GETMAVGEC = 'GET_MAVG_EC';

// TDS
export const GETALLTDS = 'GET_ALL_TDS';
export const GETLASTTDS = 'GET_LAST_TDS';
export const GETMAVGTDS = 'GET_MAVG_TDS';

// ORP
export const GETALLORP = 'GET_ALL_ORP';
export const GETLASTORP = 'GET_LAST_ORP';
export const GETMAVGORP = 'GET_MAVG_ORP';

// Oxygen
export const GETALLDO = 'GET_ALL_DO';
export const GETLASTDO = 'GET_LAST_DO';
export const GETMAVGDO = 'GET_MAVG_DO';

// All Analysis
export const GETALLNO = 'GET_ALL_NO';
export const GETALLTWODATE = 'GET_ALL_TWO_DATE';
export const GETALLWEEKLY = 'GET_ALL_WEEKLY';
export const GETLASTALL = 'GET_LAST_ALL';
export const GETTANKSTATUS = 'GET_TANK_STATUS';

// Dosage 
export const GETLASTFIVEDOSAGE = 'GET_LAST_FIVE_DOSAGE';
export const GETALLWEEKLYDOSAGE = 'GET_ALL_WEEKLY_DOSAGE';
export const GETALLTWOWEEKLYDOSAGE = 'GET_ALL_TWO_WEEKLY_DOSAGE';
export const GETALLNODOSAGE = 'GET_ALL_NO_DOSAGE';
export const GETTWODATEDOSAGE = 'GET_TWO_DATE_DOSAGE';

// Chemical 1
export const GETTWOWEEKCHEM1 = 'GET_TWO_WEEK_CHEM1';
export const GETLASTCHEM1 = 'GET_LAST_CHEM_1';
export const GETMAVGCHEM1 = 'GET_MAVG_CHEM_1';

// Chemical 2
export const GETTWOWEEKCHEM2 = 'GET_TWO_WEEK_CHEM2';
export const GETLASTCHEM2 = 'GET_LAST_CHEM_2';
export const GETMAVGCHEM2 = 'GET_MAVG_CHEM_2';

// Chemical 3 
export const GETTWOWEEKCHEM3 = 'GET_TWO_WEEK_CHEM3';
export const GETLASTCHEM3 = 'GET_LAST_CHEM_3';
export const GETMAVGCHEM3 = 'GET_MAVG_CHEM_3';