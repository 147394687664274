/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// components
import H2OMonitor from 'assets/img/h2omon_logo.png';
import IndexDropdown from "components/Dropdowns/IndexDropdown.js";


export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between  px-2 py-3 navbar-expand-lg bg-gray-900">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between ">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-white bg-white rounded-full text-sm font-bold leading-relaxed inline-block mr-4 whitespace-no-wrap uppercase"
            >
              <img src={H2OMonitor} width="50px" className="text-sm border rounded-full" />
            </Link>
            <Link
              to="/"
              className="text-white text-sm font-bold leading-relaxed py-3 inline-block mr-4 whitespace-no-wrap uppercase lg:hidden  "
            >
              H2OMonitor
            </Link>
            <button
              className="cursor-pointer text-white text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center  lg:bg-transparent  rounded-lg" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none mr-auto">
              <li className="flex items-center">
                <Link
                  className="hover:text-gray-600 text-lg text-white mr-3 py-4 lg:py-2 flex items-center cursor-pointer font-bold ml-3"
                  to="/"
                >
                  H2OMonitor
                </Link>
              </li>
              <li className="flex items-center hover:text-gray-600 text-white">
                <a
                  className="hover:text-gray-600 text-white px-3 py-4 lg:py-2 flex items-center cursor-pointer text-xs uppercase font-bold"
                  onClick={() => void (Tawk_API.maximize())}
                >
                  <i className="fas fa-mug-hot text-lg leading-lg mr-2" />

                  {t('contact-us')}
                </a>
              </li>
            </ul>
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto text-white">
              <li className="flex items-center ">
                <IndexDropdown text="text-white hover:text-gray-600" />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
