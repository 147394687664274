
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTwoDateDosage } from 'actions/'
import { Translation } from 'react-i18next';
import Moment from 'react-moment';
import { Pagination } from 'components/Pagination/Pagination';

class AllDosages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date1: "",
            date2: "",
            emptyAlert: false,

            currentPage: 1,
            perPage: 50,
            upperPageBound: 10,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3
        }
    }
    onChangeDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    newDate = (tarih) => {
        const newTarih = new Date(tarih);
        const dd = String(newTarih.getDate()).padStart(2, '0');
        const mm = String(newTarih.getMonth() + 1).padStart(2, '0');
        const yyyy = newTarih.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    }
    nowDate = () => {
        const now = new Date();
        const dd = String(now.getDate()).padStart(2, '0');
        const mm = String(now.getMonth() + 1).padStart(2, '0');
        const yyyy = now.getFullYear();
        return (`${yyyy}-${mm}-${dd}`);
    }
    minDateFunc = () => {
        if (this.state.date1 !== "") {
            return this.newDate(this.state.date1)
        }
        return "2000-01-01";
    }
    maxDateFunc = () => {
        if (this.state.date2 !== "") {
            return this.newDate(this.state.date2);
        }
        return this.nowDate()
    }


    onSubmitDate = () => {
        if (this.state.date1 !== "" && this.state.date2 !== "") {
            this.setState({
                emptyAlert: false
            });
            const date1 = new Date(this.state.date1);
            const date2 = new Date(this.state.date2);
            this.props.getTwoDateDosage(date1, date2, "dosages");
        } else {
            this.setState({
                emptyAlert: true
            });
            setTimeout(() => {
                this.setState({
                    emptyAlert: false
                })
            }, 5000);
        }
    }
    render() {
        const { twoDate } = this.props;
        const { currentPage, perPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;
        const indexOfLast = currentPage * perPage;
        const indexOfFirst = indexOfLast - perPage;
        const currentValue = twoDate.slice(indexOfFirst, indexOfLast);
        const handleClick = (event) => {
            let listid = Number(event.target.id);
            this.setState({
                currentPage: listid
            });
            setPrevAndNextBtnClass(listid);
        }
        const setPrevAndNextBtnClass = (listid) => {
            let totalPage = Math.ceil(twoDate.length / perPage);
            this.setState({ isNextBtnActive: 'disabled' });
            this.setState({ isPrevBtnActive: 'disabled' });
            if (totalPage === listid && totalPage > 1) {
                this.setState({ isPrevBtnActive: '' });
            }
            else if (listid === 1 && totalPage > 1) {
                this.setState({ isNextBtnActive: '' });
            }
            else if (totalPage > 1) {
                this.setState({ isNextBtnActive: '' });
                this.setState({ isPrevBtnActive: '' });
            }
        }
        const btnIncrementClick = () => {
            this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
            let listid = this.state.upperPageBound + 1;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        const btnDecrementClick = () => {
            this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
            let listid = this.state.upperPageBound - this.state.pageBound;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        const btnPrevClick = () => {
            if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
                this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
                this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
            }
            let listid = this.state.currentPage - 1;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        const btnNextClick = () => {
            if ((this.state.currentPage + 1) > this.state.upperPageBound) {
                this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
                this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
            }
            let listid = this.state.currentPage + 1;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        return (
            <>
                <div className="flex flex-wrap justify-center p-4">
                    <div className="relative flex flex-col min-w-0 p-4 break-words bg-white w-full mb shadow-lg rounded-lg ">
                        <div className="flex justify-center">
                            <div className="p-2"> <input type="date" max={this.maxDateFunc()} value={this.state.date1} name="date1" id="date1" onChange={this.onChangeDate} /></div>
                            <div className="p-2"> <i className="fas fa-arrow-right"></i></div>
                            <div className="p-2"> <input type="date" min={this.minDateFunc()} max={this.nowDate()} name="date2" onChange={this.onChangeDate} /></div>
                            <div className="p-2"> <button onClick={this.onSubmitDate}><i className="fas fa-search"></i></button></div>
                        </div>
                        <div id="twoDateError" className="flex justify-center">
                            <div className=
                                {
                                    this.state.emptyAlert ?
                                        "block text-white text-center mt-2 align-middle p-4 border-0 rounded-lg relative w-full bg-red-500"
                                        : "hidden"
                                }
                            >
                                <span className="text-xl inline-block mr-4 align-middle">
                                    <i className="fas fa-bell" />
                                </span>
                                <Translation>
                                    {
                                        (t) =>
                                            <span className="inline-block align-middle mr-8">
                                                {t("date-alert")}
                                            </span>
                                    }
                                </Translation>
                                <button onClick={() => this.setState({ emptyAlert: false })} className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-4 outline-none focus:outline-none">
                                    <span>×</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap p-4" id="tablo">
                    <div className="w-full">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                            <div className="rounded-t mb-0 px-4 py-3 border-0">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                        <Translation>
                                            {
                                                (t) =>
                                                    <h3 className="font-semibold text-base text-gray-800">
                                                        {
                                                            t('analysis-results')
                                                        }
                                                    </h3>
                                            }
                                        </Translation>
                                    </div>
                                </div>
                            </div>
                            <div className="block w-full overflow-x-auto">
                                {/* Projects table */}
                                <table className="items-center w-full bg-transparent border-collapse">
                                    <thead>
                                        <Translation>
                                            {(t) =>
                                                <tr>
                                                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('no-time')}
                                                    </th>
                                                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('chem-1')}
                                                    </th>
                                                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('chem-2')}
                                                    </th>
                                                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('chem-3')}
                                                    </th>
                                                    <th className="px-4 bg-gray-100 text-gray-600  align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('perf-score')}
                                                    </th>
                                                    {/* <th className="px-4 bg-gray-100 text-gray-600 align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('do')}
                                                    </th>
                                                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('temp')}
                                                    </th>
                                                    <th className="px-4 bg-gray-100 text-gray-600 align-middle border-solid border-gray-200 py-3 text-xs  border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                        {t('perf-score')}
                                                    </th> */}
                                                </tr>
                                            }
                                        </Translation>
                                    </thead>
                                    <tbody>
                                        {
                                            currentValue.length !== 0 ?
                                                currentValue.map(analysis => {
                                                    return (
                                                        <tr key={analysis.no2}>
                                                            <th className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                                                                <Moment format="HH:mm, DD/MM/YYYY">{analysis.date}</Moment>
                                                            </th>
                                                            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
                                                                {analysis.K1 !== undefined ? analysis.K1.toFixed(2) : "--"} ml
                                                        </td>
                                                            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
                                                                {analysis.K2 !== undefined ? analysis.K2.toFixed(2) : "--"} ml
                                                        </td>
                                                            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
                                                                {analysis.K3 !== undefined ? analysis.K3.toFixed(2) : "--"} ml
                                                        </td>
                                                            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
                                                                {analysis.score !== undefined ? analysis.score.toFixed(2) : "--"} /100
                                                        </td>
                                                        </tr>
                                                    )
                                                })
                                                : null
                                        }
                                    </tbody>
                                </table>
                                {
                                    currentValue.length !== 0 ?
                                        <Pagination
                                            perValue={perPage}
                                            totalValues={twoDate.length}
                                            upperPageBound={upperPageBound}
                                            lowerPageBound={lowerPageBound}
                                            isPrevBtnActive={isPrevBtnActive}
                                            isNextBtnActive={isNextBtnActive}
                                            currentPage={currentPage}
                                            handleClick={handleClick}
                                            btnNextClick={btnNextClick}
                                            btnPrevClick={btnPrevClick}
                                            btnDecrementClick={btnDecrementClick}
                                            btnIncrementClick={btnIncrementClick}
                                        />
                                        : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        twoDate: state.twoDateDosage
    }
}
export default connect(mapStateToProps, { getTwoDateDosage })(AllDosages);