import React from "react";
import { createPopper } from "@popperjs/core";
import Moment from 'react-moment'
const Popover = ({ time }) => {
    const [popoverShow, setPopoverShow] = React.useState(false);
    const btnRef = React.createRef();
    const popoverRef = React.createRef();
    const openTooltip = () => {
        createPopper(btnRef.current, popoverRef.current, {
            placement: "right"
        });
        setPopoverShow(true);
    };
    const closeTooltip = () => {
        setPopoverShow(false);
    };
    return (
        <>

            <button
                className="ml-3" type="button"
                onMouseEnter={openTooltip}
                onMouseLeave={closeTooltip}
                ref={btnRef}
            >
                <i className="fas fa-info-circle text-gray-500 hover:shadow-lg rounded-full ease-linear transition-all duration-150"></i>
            </button>
            <div
                className={
                    (popoverShow ? "" : "hidden ") +
                    "bg-gray-100 border-0 mr-3  block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                }
                ref={popoverRef}
            >
                <div>

                    <div className="text-black p-3">
                        <Moment format="HH:mm, DD/MM/YYYY">
                            {time}
                        </Moment>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Popover;

