import Loading from "components/Loading/Loading";
import React from "react";
import { useTranslation } from 'react-i18next';
// components

const CardSocialTraffic = ({ tank }) => {
  const { t } = useTranslation();
  const tankLevel = (level) => {
    switch (level) {
      case 0: return t('low')
      case 1: return t('normal')
      case 2: return t('high')
      default:
        break;
    }
  }
  const tankLevelBar = (level) => {
    switch (level) {
      case 0: return "0"
      case 1: return "50"
      case 2: return "100"
      default:
        break;
    }
  }
  return (

    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
        {
          tank[0] !== undefined ?
            <>
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-gray-800">
                      {t('tank-status')}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                {/* Projects table */}
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead className="thead-light">
                    <tr>
                      <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Tank
                </th>
                      <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                        Amount
                </th>
                      <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left min-w-140-px">
                        Level
                </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        Tank 1
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {tank[0].L1.amount}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        <div className="flex items-center">
                          <span className="w-16 text-left">{tankLevel(tank[0].L1.level)}</span>
                          <div className="relative w-full ml-auto">
                            <div className={tank[0].L1.level === 0 ? "bg-red-200 overflow-hidden h-2 text-xs flex rounded" : " bg-gray-200 overflow-hidden h-2 text-xs flex rounded"}>
                              <div
                                style={{ width: tankLevelBar(tank[0].L1.level) + "%" }}
                                className={tank[0].L1.level === 1 ? "bg-green-500 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center" : "bg-red-500 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        Tank 2
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {tank[0].L2.amount}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        <div className="flex items-center">
                          <span className="w-16 text-left">{tankLevel(tank[0].L2.level)}</span>
                          <div className="relative w-full ml-auto">
                            <div className={tank[0].L2.level === 0 ? "bg-red-200 overflow-hidden h-2 text-xs flex rounded" : " bg-gray-200 overflow-hidden h-2 text-xs flex rounded"}>
                              <div
                                style={{ width: tankLevelBar(tank[0].L2.level) + "%" }}
                                className={tank[0].L2.level === 1 ? "bg-green-500 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center" : "bg-red-500 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                        Tank 3
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        {tank[0].L3.amount}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                        <div className="flex items-center">
                          <span className="w-16 text-left">{tankLevel(tank[0].L3.level)}</span>
                          <div className="relative w-full ml-auto">
                            <div className={tank[0].L3.level === 0 ? "bg-red-200 overflow-hidden h-2 text-xs flex rounded" : " bg-gray-200 overflow-hidden h-2 text-xs flex rounded"}>
                              <div
                                style={{ width: tankLevelBar(tank[0].L3.level) + "%" }}
                                className={tank[0].L3.level === 1 ? "bg-green-500 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center" : "bg-red-500 shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
            :
            <div className="p-4">
              <Loading />
            </div>
        }
      </div>
    </>
  );
}
export default CardSocialTraffic;