import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardStats from 'components/Cards/CardStats';
import { getLastSingular, getMavgSingular } from 'actions/';
class TdsLast extends Component {
    componentDidMount() {
        this.props.getLastSingular("tds");
        this.props.getMavgSingular("tds");
    }

    render() {
        const { tdsLast, tdsMavg } = this.props;
        return (
            <CardStats
                statSubtitle="tds-last-value"
                statTitle={String(tdsLast.tds)}
                statTime="last-m-avg"
                statIconName="fas fa-disease"
                statIconColor="bg-fuchsia-300"
                Last={tdsLast.tds}
                Mavg={tdsMavg}
                time={tdsLast.date}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tdsLast: state.tdsLast,
        tdsMavg: state.tdsMavg
    }
}
export default connect(mapStateToProps, { getLastSingular, getMavgSingular })(TdsLast);