import React, { Component } from 'react'
import { connect } from 'react-redux';
import CardStats from 'components/Cards/CardStats';

import { getLastChem, getMavgChem } from 'actions/';

class Chem3Last extends Component {
    componentDidMount() {
        this.props.getLastChem("K3");
        this.props.getMavgChem("K3");
    }
    render() {
        const { chem3Mavg, chem3Last } = this.props;
        return (
            <CardStats
                statSubtitle="ph-last-value"
                statTitle={String(chem3Last.K3)}
                statTime="last-m-avg"
                statIconName="fas fa-prescription-bottle"
                statIconColor="bg-lime-300"
                Last={chem3Last.K3}
                Mavg={chem3Mavg}
                time={chem3Last.date}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        chem3Last: state.chem3Last,
        chem3Mavg: state.chem3Mavg
    }
}
export default connect(mapStateToProps, { getLastChem, getMavgChem })(Chem3Last);