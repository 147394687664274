import { LOGIN, LOGOUT } from 'actions/types';

const sessionState = {
    authanticated: false
}
export default (state = sessionState, action) => {
    if (action.type === LOGIN) {
        return {
            authanticated: true
        }
    }
    else if (action.type === LOGOUT) {
        return sessionState;
    }

    return state;
}

