import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import FooterAdmin from 'components/Footers/FooterAdmin.js';

// views

import Dashboard from 'views/admin/Dashboard.js';
import Settings from 'views/admin/Settings.js';
import Tables from 'views/admin/Tables.js';
import PhPage from 'views/admin/analysis/ph/PhPage';
import CondPage from 'views/admin/analysis/conductivity/CondPage';
import TdsPage from 'views/admin/analysis/tds/TdsPage';
import OrpPage from 'views/admin/analysis/orp/OrpPage';
import TempPage from 'views/admin/analysis/temp/TempPage';
import OxygenPage from 'views/admin/analysis/oxygen/OxygenPage';
import ChemPage1 from 'views/admin/dosage/chem-1/ChemPage1';
import ChemPage2 from 'views/admin/dosage/chem-2/ChemPage2';
import ChemPage3 from 'views/admin/dosage/chem-3/ChemPage3';
import AllPage from 'views/admin/analysis/all/AllPage';
import AllDosagePage from 'views/admin/dosage/allDosage/AllDosagePage';




class Admin extends Component {
  render() {
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 bg-gray-200">
          <AdminNavbar />
          <div className="md:px-10 mx-auto w-full -m-128">
            <Switch>
              <Route path="/admin/dashboard" exact component={Dashboard} />
              <Route path="/admin/settings" exact component={Settings} />
              <Route path="/admin/tables" exact component={Tables} />
              <Route path="/admin/ph" exact component={PhPage} />
              <Route path="/admin/cond" exact component={CondPage} />
              <Route path="/admin/tds" exact component={TdsPage} />
              <Route path="/admin/orp" exact component={OrpPage} />
              <Route path="/admin/temp" exact component={TempPage} />
              <Route path="/admin/oxygen" exact component={OxygenPage} />
              <Route path="/admin/allAnalysis" exact component={AllPage} />
              <Route path="/admin/chem1" exact component={ChemPage1} />
              <Route path="/admin/chem2" exact component={ChemPage2} />
              <Route path="/admin/chem3" exact component={ChemPage3} />
              <Route path="/admin/allDosages" exact component={AllDosagePage} />
              <Redirect from="/admin" to="/admin/dashboard" />
              <Redirect from="/" to="/admin" />
            </Switch>
            <FooterAdmin />
          </div>
        </div>
      </>
    )
  }
}

export default Admin;
