import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLogged } from 'actions/';

import AllDosages from './AllDosages';

class AllDosagePage extends Component {
    componentDidMount() {
        this.props.isLogged(this.props.history);
    }
    render() {
        return (
            <>
                <AllDosages />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps, { isLogged })(AllDosagePage);