import React, { Component } from 'react'
import { connect } from 'react-redux';
import CardStats from 'components/Cards/CardStats';

import { getLastChem, getMavgChem } from 'actions/';

class Chem2Last extends Component {
    componentDidMount() {
        this.props.getLastChem("K2");
        this.props.getMavgChem("K2");
    }
    render() {
        const { chem2Mavg, chem2Last } = this.props;
        return (
            <CardStats
                statSubtitle="ph-last-value"
                statTitle={String(chem2Last.K2)}
                statTime="last-m-avg"
                statIconName="fas fa-prescription-bottle"
                statIconColor="bg-lime-300"
                Last={chem2Last.K2}
                Mavg={chem2Mavg}
                time={chem2Last.date}
            />
        )
    }
}
const mapStateToProps = (state) => {

    return {
        chem2Last: state.chem2Last,
        chem2Mavg: state.chem2Mavg
    }
}
export default connect(mapStateToProps, { getLastChem, getMavgChem })(Chem2Last);