import axios from 'axios';
import { API } from './api'
import {
    GETALLPH,
    GETLASTPH,
    GETMAVGPH,
    GETLASTPERF,
    GETMAVGPERF,
    GETLASTTEMP,
    GETMAVGTEMP,
    GETALLEC,
    GETLASTEC,
    GETMAVGEC,
    GETALLTDS,
    GETLASTTDS,
    GETMAVGTDS,
    GETALLORP,
    GETLASTORP,
    GETMAVGORP,
    GETALLDO,
    GETLASTDO,
    GETMAVGDO,
    GETALLTEMP,
    GETALLTWODATE
} from './types';
export const getAllTwoDate = (date1, date2, page) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        const day1 = String(date1.getDate());
        const year1 = String(date1.getFullYear());
        const month1 = String(date1.getMonth() + 1);
        const day2 = String(date2.getDate());
        const year2 = String(date2.getFullYear());
        const month2 = String(date2.getMonth() + 1);
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/a/Get2Dates/${year1}/${month1}/${day1}/${year2}/${month2}/${day2}?token=${token}`);
            switch (page) {
                case "analysis":
                    dispatch({
                        type: GETALLTWODATE,
                        payload: response.data.reverse()
                    });
                    break;
                case "ph":
                    dispatch({
                        type: GETALLPH,
                        payload: response.data
                    });
                    break;
                case "ec":
                    dispatch({
                        type: GETALLEC,
                        payload: response.data.reverse()
                    });
                    break;
                case "tds":
                    dispatch({
                        type: GETALLTDS,
                        payload: response.data.reverse()
                    });
                    break;
                case "orp":
                    dispatch({
                        type: GETALLORP,
                        payload: response.data.reverse()
                    });
                    break;
                case "do":
                    dispatch({
                        type: GETALLDO,
                        payload: response.data.reverse()
                    });
                    break;
                case "temp":
                    dispatch({
                        type: GETALLTEMP,
                        payload: response.data.reverse()
                    });
                    break;
                default:
                    break;
            }
        }
    }
}
export const getAllSingular = (all) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        const nowDate = new Date();
        const pastDate = nowDate.getDate() - 14;
        nowDate.setDate(pastDate);
        const day = String(nowDate.getDate());
        const year = String(nowDate.getFullYear());
        const month = String(nowDate.getMonth() + 1);
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/a/Get2Dates/${year}/${month}/${day}?token=${token}`);
            switch (all) {
                case "ph":
                    dispatch({
                        type: GETALLPH,
                        payload: response.data.reverse()
                    })
                    break;
                case "ec":
                    dispatch({
                        type: GETALLEC,
                        payload: response.data.reverse()
                    })
                    break;
                case "tds":
                    dispatch({
                        type: GETALLTDS,
                        payload: response.data.reverse()
                    })
                    break;
                case "orp":
                    dispatch({
                        type: GETALLORP,
                        payload: response.data.reverse()
                    })
                    break;
                case "do":
                    dispatch({
                        type: GETALLDO,
                        payload: response.data.reverse()
                    })
                    break;
                case "temp":
                    dispatch({
                        type: GETALLTEMP,
                        payload: response.data.reverse()
                    })
                    break;
                default:
                    break;
            }

        }
    }
}

export const getLastSingular = (last) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/a/${last}/last?token=${token}`);
            switch (last) {
                case "ph":
                    dispatch({
                        type: GETLASTPH,
                        payload: response.data
                    });
                    break;
                case "score":
                    dispatch({
                        type: GETLASTPERF,
                        payload: response.data
                    });
                    break;
                case "temp":
                    dispatch({
                        type: GETLASTTEMP,
                        payload: response.data
                    });
                    break;
                case "ec":
                    dispatch({
                        type: GETLASTEC,
                        payload: response.data
                    });
                    break;
                case "tds":
                    dispatch({
                        type: GETLASTTDS,
                        payload: response.data
                    });
                    break;
                case "orp":
                    dispatch({
                        type: GETLASTORP,
                        payload: response.data
                    });
                    break;
                case "do":
                    dispatch({
                        type: GETLASTDO,
                        payload: response.data
                    });
                    break;
                default:
                    break;
            }

        }
    }
}

export const getMavgSingular = (mavg) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/a/${mavg}/1mavg?token=${token}`);
            switch (mavg) {
                case "ph":
                    dispatch({
                        type: GETMAVGPH,
                        payload: response.data
                    });
                    break;
                case "score":
                    dispatch({
                        type: GETMAVGPERF,
                        payload: response.data
                    });
                    break;
                case "temp":
                    dispatch({
                        type: GETMAVGTEMP,
                        payload: response.data
                    });
                    break;
                case "ec":
                    dispatch({
                        type: GETMAVGEC,
                        payload: response.data
                    });
                    break;
                case "tds":
                    dispatch({
                        type: GETMAVGTDS,
                        payload: response.data
                    });
                    break;
                case "orp":
                    dispatch({
                        type: GETMAVGORP,
                        payload: response.data
                    });
                    break;
                case "do":
                    dispatch({
                        type: GETMAVGDO,
                        payload: response.data
                    });
                    break;
                default:
                    break;
            }

        }
    }
}