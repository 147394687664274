import React from 'react';
export const Pagination = ({ perValue, totalValues, handleClick, btnNextClick, btnPrevClick, btnDecrementClick, btnIncrementClick, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive, currentPage }) => {

    const pageNumber = [];
    for (let i = 1; i <= Math.ceil(totalValues / perValue); i++) {
        pageNumber.push(i);
    }
    // eslint-disable-next-line array-callback-return
    const renderPageNumbers = pageNumber.map((number) => {
        if (number === currentPage) {
            return (
                <li key={number} id={number}>
                    <a href="#tablo" id={number} onClick={handleClick} className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 text-white bg-blue-500">
                        {number}
                    </a>
                </li>

            )
        }
        else if ((number < upperPageBound + 1) && number > lowerPageBound) {
            return (
                <li key={number} id={number}>
                    <a href="#tablo" id={number} onClick={handleClick} className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500">
                        {number}
                    </a>
                </li>

            )
        }
    });

    let pageIncrementBtn = null;
    if (pageNumber.length > upperPageBound) {
        pageIncrementBtn =
            <li>
                <a href="#tablo" onClick={btnIncrementClick} className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500">
                    <i className="fas fa-chevron-right -mr-px"></i>
                    <i className="fas fa-chevron-right -mr-px"></i>
                </a>
            </li>
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
        pageDecrementBtn =
            <li>
                <a href="#tablo" onClick={btnDecrementClick} className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500">
                    <i className="fas fa-chevron-left -ml-px"></i>
                    <i className="fas fa-chevron-left -ml-px"></i>
                </a>
            </li>
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === 'disabled') {
        renderPrevBtn =
            <li className={`${isPrevBtnActive}`}>
                <a href="#tablo" id="btnPrev" className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500">
                    <i className="fas fa-chevron-left -ml-px"></i>
                </a>
            </li>
    }
    else {
        renderPrevBtn =
            <li className={`${isPrevBtnActive}`}>
                <a href="#tablo" id="btnPrev" onClick={btnPrevClick} className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500">
                    <i className="fas fa-chevron-left -ml-px"></i>
                </a>
            </li>
    }
    let renderNextBtn = null;
    if (isNextBtnActive === 'disabled') {
        renderNextBtn =
            <li className={`${isNextBtnActive}`}>
                <a href="#tablo" id="btnNext" className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500">
                    <i className="fas fa-chevron-right -mr-px"></i>
                </a>
            </li>
    }
    else {
        renderNextBtn =
            <li className={`${isNextBtnActive}`}>
                <a href="#tablo" id="btnNext" onClick={btnNextClick} className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blue-500 bg-white text-blue-500">
                    <i className="fas fa-chevron-right -mr-px"></i>
                </a>
            </li>
    }
    return (
        <div className="flex justify-center py-2">
            <nav className="block">
                <ul className="flex pl-0 rounded list-none flex-wrap">
                    {renderPrevBtn}
                    {pageDecrementBtn}
                    {renderPageNumbers}
                    {pageIncrementBtn}
                    {renderNextBtn}
                </ul>
            </nav>
        </div>
    )
}

export default Pagination;