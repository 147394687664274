import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { getLastThreeDosage } from 'actions/';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

class AllLastDosage extends Component {
    componentDidMount() {
        this.props.getLastThreeDosage();
    }

    render() {
        const { lastDosages } = this.props;
        return (
            <Translation>
                {
                    t =>
                        <>
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                                <div className="rounded-t mb-0 px-4 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                                            <h3 className="font-semibold text-base text-gray-800">
                                                {t('last-three-dosage')}
                                            </h3>
                                        </div>
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                                            <Link
                                                to="/admin/allDosages"
                                                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            >
                                                {t('see-all')}

                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="block w-full overflow-x-auto">
                                    {/* Projects table */}
                                    <table className="items-center w-full bg-transparent border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                    {t('no-time')}
                                                </th>
                                                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                    {t('chem-1')}
                                                </th>
                                                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                    {t('chem-2')}
                                                </th>
                                                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
                                                    {t('chem-3')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                lastDosages !== undefined ?
                                                    lastDosages.map(dosage => {
                                                        return (
                                                            <tr key={dosage.no2}>
                                                                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left">
                                                                    <Moment format="HH:mm, DD/MM/YYYY">{dosage.date}</Moment>
                                                                </th>
                                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                                    {dosage.K1}
                                                                </td>
                                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                                    {dosage.K2}
                                                                </td>
                                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                                    {dosage.K3}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                }
            </Translation>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        lastDosages: state.lastDosages
    }
}
export default connect(mapStateToProps, { getLastThreeDosage })(AllLastDosage);