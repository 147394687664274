import React, { Component } from "react";

import { connect } from "react-redux"
import { getAllNo } from "actions";
import ModalValues from "./ModalValues";
import Loading from 'components/Loading/Loading';

class Modal extends Component {
  componentDidMount() {
    const id = this.props.analysisId;
    this.props.getAllNo(id);
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        this.props.setShowModal(false);
        this.props.allValues[0] = undefined;
      };
    });
  }
  render() {
    const { allValues } = this.props;
    return (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto max-w-sm">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*body*/}
              <div className="relative p-6 flex-auto">
                {
                  allValues[0] !== undefined ?
                    <ModalValues
                      no={allValues[0].no}
                      ph={allValues[0].ph}
                      tds={allValues[0].tds}
                      orp={allValues[0].orp}
                      dO={allValues[0].do}
                      temp={allValues[0].temp}
                      score={allValues[0].score}
                      ec={allValues[0].ec}
                      date={allValues[0].date}
                    /> :
                    <Loading />
                }

              </div>
              {/*footer*/}
              <div className="flex  items-center justify-center border-t py-2 border-solid border-gray-300 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase  text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    this.props.setShowModal(false);
                    allValues[0] = undefined;
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    allValues: state.analysisNoAll
  }
}

export default connect(mapStateToProps, { getAllNo })(Modal);