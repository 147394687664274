import CardLineChart from 'components/Cards/CardLineChart';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllWeekly } from "actions/";

class MainGraphic extends Component {
    componentDidMount() {
        this.props.getAllWeekly();
    }
    valueToGraphic = (weekly) => {
        const data = [], labels = [];
        if (weekly.length !== 0) {
            weekly.map(value => {
                if (value.score !== undefined) {
                    data.push(Number(value.score.toFixed(1)));
                } else {
                    data.push(Number(0));
                }
                const date = new Date(value.date);
                const labelDate = `${date.getUTCHours()}:${date.getUTCMinutes()}, ${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
                labels.push(labelDate);
                return {
                    data,
                    labels
                };
            });
        }
        return {
            data,
            labels
        }
    }
    render() {
        const { weekly } = this.props;
        return (
            <>
                <CardLineChart
                    data={this.valueToGraphic(weekly)}
                    page={"perf-score"}
                />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        weekly: state.weekly
    }
}
export default connect(mapStateToProps, { getAllWeekly })(MainGraphic);