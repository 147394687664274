import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLogged } from 'actions/authAction';
import Perf from 'views/admin/analysis/perf/lastPerf';
import ChemLast3 from './ChemLast3';
import Chem3Graphic from './Chem3Graphic';
import Chem3Table from './Chem3Table';
class ChemPage3 extends Component {
    componentDidMount() {
        this.props.isLogged(this.props.history);
    }
    render() {
        return (
            <>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12">
                        <div className="flex flex-wrap ">
                            <div className="p-4 w-full">
                                <ChemLast3 />
                            </div>
                            <div className="w-full p-4">
                                <Perf />
                            </div>
                            <div className="w-full p-4">

                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-8/12 p-4 mb-0">
                        <Chem3Graphic />
                    </div>
                </div>
                <Chem3Table />
            </>
        )
    }
}
export default connect(null, { isLogged })(ChemPage3);