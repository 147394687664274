import React, { Component } from 'react'
import Perf from '../perf/lastPerf';
import TempLast from '../temp/TempLast';
import { connect } from 'react-redux';
import { isLogged } from 'actions/authAction';
import OxygenLast from './OxygenLast';
import OxygenTable from './OxygenTable';
import OxygenGraphic from './OxygenGraphic';

class OxygenPage extends Component {
    componentDidMount() {
        this.props.isLogged(this.props.history);
    }
    render() {
        return (
            <>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12">
                        <div className="flex flex-wrap ">
                            <div className="p-4 w-full">
                                <OxygenLast />
                            </div>
                            <div className="w-full p-4">
                                <Perf />
                            </div>
                            <div className="w-full p-4">
                                <TempLast />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-8/12 p-4 mb-0">
                        <OxygenGraphic />
                    </div>
                </div>
                <OxygenTable />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps, { isLogged })(OxygenPage);