import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardStats from 'components/Cards/CardStats';
import { getLastSingular, getMavgSingular } from 'actions/';

class OxygenLast extends Component {
    componentDidMount() {
        this.props.getLastSingular("do");
        this.props.getMavgSingular("do");
    }

    render() {
        const { doLast, doMavg } = this.props;
        return (
            <CardStats
                statSubtitle="do-last-value"
                statTitle={String(doLast.do)}
                statTime="last-m-avg"
                statIconName="fas fa-atom"
                statIconColor="bg-rose-300"
                Last={doLast.do}
                Mavg={doMavg}
                time={doLast.date}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        doLast: state.doLast,
        doMavg: state.doMavg
    }
}
export default connect(mapStateToProps, { getLastSingular, getMavgSingular })(OxygenLast);