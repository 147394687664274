import React from 'react'
import loadingImg from 'assets/img/loading.svg';

export default function Loading() {
    return (
        <div className="relative flex flex-col items-center">
            <img src={loadingImg} width="40" />
        </div>
    )
}
