import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLogged } from 'actions/authAction';
import Perf from 'views/admin/analysis/perf/lastPerf';
import ChemLast1 from './ChemLast1';
import Chem1Graphic from './Chem1Graphic';
import Chem1Table from './Chem1Table';
class ChemPage1 extends Component {
    componentDidMount() {
        this.props.isLogged(this.props.history);
    }
    render() {
        return (
            <>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12">
                        <div className="flex flex-wrap ">
                            <div className="p-4 w-full">
                                <ChemLast1 />
                            </div>
                            <div className="w-full p-4">
                                <Perf />
                            </div>
                            <div className="w-full p-4">

                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-8/12 p-4 mb-0">
                        <Chem1Graphic />
                    </div>
                </div>
                <Chem1Table />
            </>
        )
    }
}
export default connect(null, { isLogged })(ChemPage1);