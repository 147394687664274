import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAllWeekly } from 'actions/'
import ChartLine from 'components/Cards/ChartLine';

class TdsGraphic extends Component {
    componentDidMount() {
        this.props.getAllWeekly();
    }
    render() {
        const { weekly } = this.props;
        return (
            <>
                <ChartLine
                    data={weekly}
                    page={"tds"}
                />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        weekly: state.weekly
    }
}
export default connect(mapStateToProps, { getAllWeekly })(TdsGraphic);