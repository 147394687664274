import React, { Component } from "react";

import { connect } from "react-redux"
import { getAllNoDosage } from "actions";
import Loading from 'components/Loading/Loading';
import { Translation } from "react-i18next";
import Moment from 'react-moment';
class ModalDosage extends Component {
    componentDidMount() {
        const id = this.props.analysisId;
        this.props.getAllNoDosage(id);
    }
    render() {
        const { allValues } = this.props;

        return (
            <>
                <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto my-6 mx-auto max-w-sm">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                {
                                    allValues[0] !== undefined ?
                                        <div className="items-start text-center">
                                            <Translation>
                                                {t =>
                                                    <>
                                                        <h4 className=" font-bold uppercase mb-2 text-gray-900">{t('this-analysis-result')} </h4>
                                                        <div className="px-4 mb-1 -mt-2">
                                                            <div className="flex items-start justify-center py-3 text-sm">
                                                                <div className="flex items-center text-gray-900">
                                                                    <span><Moment format="HH:mm, DD/MM/YYYY">{allValues[0].date}</Moment></span>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                                                                <div className="flex items-start text-gray-900">
                                                                    <span>No</span>
                                                                </div>
                                                                <span className="ml-32 text-gray-900 ">{allValues[0].no2}</span>
                                                            </div>
                                                            <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                                                                <div className="flex items-start text-gray-900">
                                                                    <span>{t('perf-score')}</span>
                                                                </div>
                                                                <span className="ml-32 text-gray-900 ">{allValues[0].score} <span className="text-xs">/100</span></span>
                                                            </div>
                                                            <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                                                                <div className="flex items-start text-gray-900">
                                                                    <span>{t('chem-1')}</span>
                                                                </div>
                                                                <span className="ml-32 text-gray-900 ">{allValues[0].K1}</span>
                                                            </div>
                                                            <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                                                                <div className="flex items-start text-gray-900">
                                                                    <span>{t('chem-2')}</span>
                                                                </div>
                                                                <span className="ml-32 text-gray-900 ">{allValues[0].K2}</span>
                                                            </div>
                                                            <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                                                                <div className="flex items-center text-gray-900">
                                                                    <span>{t('chem-3')}</span>
                                                                </div>
                                                                <span className="ml-32 text-gray-900 ">{allValues[0].K3}</span>
                                                            </div>
                                                            <div className="flex items-start justify-between py-3 text-sm">
                                                                {
                                                                    allValues[0].job.completed_date === undefined ?
                                                                        <>
                                                                            <div className="flex items-center text-gray-900">
                                                                                <span>{t('chem-3')}</span>
                                                                            </div>
                                                                            <span className="ml-2 text-gray-900 "><Moment format="HH:mm, DD/MM/YYYY">{allValues[0].job.completed_date}</Moment></span>
                                                                        </> :
                                                                        <div className="flex items-center text-gray-900">
                                                                            <span>Dozajlama Tamamlanmadı.</span>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </Translation>
                                        </div>
                                        :
                                        <Loading />
                                }

                            </div>
                            {/*footer*/}
                            <div className="flex  items-center justify-center border-t py-2 border-solid border-gray-300 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase  text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                        this.props.setShowModal(false);
                                        allValues[0] = undefined;
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        allValues: state.dosageNoAll
    }
}

export default connect(mapStateToProps, { getAllNoDosage })(ModalDosage);