import React from "react";
import { createPopper } from "@popperjs/core";
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import history from '../../history'
import ModalDosage from "components/Modal/ModalDosage";
const NotificationDropdown = ({ analysisId, dosage }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { t } = useTranslation();


  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  return (
    <>
      <a
        className="text-gray-600 py-1 px-3 cursor-pointer"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white relative text-base z-40 float-left py-2 list-none text-left rounded-lg shadow-lg min-w-48"
        }
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <a

          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800 cursor-pointer"
          }
          onClick={(e) => {
            setShowModal(true)
            e.preventDefault()
          }}
        >
          {t('show-this-analysis-result')}
        </a>


        {
          dosage ?
            <a

              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800 cursor-pointer"
              }
              onClick={(e) => {
                setShowModal2(true)
                e.preventDefault()
              }}
            >
             {t('show-this-dosages-result')}
            </a> : null
        }
        {
          dosage ?
            <a

              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800 cursor-pointer"
              }
              onClick={(e) => {
                history.push('/admin/allDosages')
              }}
            >
              {t('show-all-dosages-results')}
            </a> :
            <a

              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800 cursor-pointer"
              }
              onClick={(e) => {
                history.push('/admin/allAnalysis')
              }}
            >
              {t('show-all-analysis-results')}
            </a>
        }

      </div>
      {
        showModal ? (
          <Modal
            setShowModal={setShowModal}
            analysisId={analysisId}
          />
        ) : null
      }
      {
        showModal2 ? (
          <ModalDosage
            setShowModal={setShowModal2}
            analysisId={analysisId}
          />
        ) : null
      }
    </>
  );
};

export default NotificationDropdown;
