import React, { Component } from 'react';
import PhLast from './PhLast';
import Perf from '../perf/lastPerf';
import { connect } from 'react-redux';
import { isLogged } from 'actions/';
import PhTable from './PhTable';
import TempLast from '../temp/TempLast';
import PhGraphic from './PhGraphic';

class PhPage extends Component {
    componentDidMount() {
        this.props.isLogged(this.props.history);
    }
    render() {

        return (
            <>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12">
                        <div className="flex flex-wrap ">
                            <div className="p-4 w-full">
                                <PhLast />
                            </div>
                            <div className="w-full p-4">
                                <Perf />
                            </div>
                            <div className="w-full p-4">
                                <TempLast />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-8/12 p-4 mb-0">
                        <PhGraphic />
                    </div>
                </div>
                <PhTable />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { isLogged })(PhPage);