import React, { Component } from 'react'
import { connect } from 'react-redux';
import CardStats from 'components/Cards/CardStats';

import { getLastChem, getMavgChem } from 'actions/';

class Chem1Last extends Component {
    componentDidMount() {
        this.props.getLastChem("K1");
        this.props.getMavgChem("K1");
    }
    render() {
        const { chem1Mavg, chem1Last } = this.props;
        return (
            <CardStats
                statSubtitle="ph-last-value"
                statTitle={String(chem1Last.K1)}
                statTime="last-m-avg"
                statIconName="fas fa-prescription-bottle"
                statIconColor="bg-lime-300"
                Last={chem1Last.K1}
                Mavg={chem1Mavg}
                time={chem1Last.date}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        chem1Last: state.chem1Last,
        chem1Mavg: state.chem1Mavg
    }
}
export default connect(mapStateToProps, { getLastChem, getMavgChem })(Chem1Last);