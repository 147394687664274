import React from 'react'

import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

export default function ModalValues({ no, ph, temp, ec, tds, orp, dO, score, date }) {
    const { t } = useTranslation();
    return (
        <div className="items-start text-center">
            <h4 className=" font-bold uppercase mb-2 text-gray-900">{t('this-analysis-result')} </h4>
            <div className="px-4 mb-1 -mt-2">
                <div className="flex items-start justify-center py-3 text-sm">
                    <div className="flex items-center text-gray-900">
                        <span><Moment format="HH:mm, DD/MM/YYYY">{date}</Moment></span>
                    </div>
                    {/* <span className="ml-32 text-gray-900 ">{date}</span> */}
                </div>
                <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                    <div className="flex items-start text-gray-900">
                        <span>No</span>
                    </div>
                    <span className="ml-32 text-gray-900 ">{no}</span>
                </div>
                <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                    <div className="flex items-start text-gray-900">
                        <span>{t('perf-score')}</span>
                    </div>
                    <span className="ml-32 text-gray-900 ">{score} <span className="text-xs">/100</span></span>
                </div>
                <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                    <div className="flex items-start text-gray-900">
                        <span>pH</span>
                    </div>
                    <span className="ml-32 text-gray-900 ">{ph}</span>
                </div>
                <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                    <div className="flex items-start text-gray-900">
                        <span>{t('do')}</span>
                    </div>
                    <span className="ml-32 text-gray-900 ">{dO}</span>
                </div>
                <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                    <div className="flex items-center text-gray-900">
                        <span>{t('orp')}</span>
                    </div>
                    <span className="ml-32 text-gray-900 ">{orp} mV</span>
                </div>
                <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                    <div className="flex items-center text-gray-900">
                        <span>TDS</span>
                    </div>
                    <span className="ml-32 text-gray-900 ">{tds}</span>
                </div>
                <div className="flex items-start border-b-2 justify-between py-3 text-sm">
                    <div className="flex items-center text-gray-900">
                        <span>{t('elec-cond')}</span>
                    </div>
                    <span className="ml-30 text-green-800">{ec} mV</span>
                </div>
                <div className="flex items-start justify-between py-3 text-sm">
                    <div className="flex items-center text-gray-900">
                        <span>{t('temp')}</span>
                    </div>
                    <span className="ml-32 text-gray-900">{temp} °C</span>
                </div>
            </div>
        </div>
    )
}
