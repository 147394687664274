import React, { Component } from 'react';
import { connect } from 'react-redux';
import GaugeChart from 'react-gauge-chart';
import { Translation } from 'react-i18next';
import { getLastAll } from 'actions/';
import Moment from 'react-moment';
import Loading from 'components/Loading/Loading';
import MainScorePopover from 'components/Popovers/MainScorePopover';
class MainLastScore extends Component {
    componentDidMount() {
        this.props.getLastAll();
    }
    render() {
        const { lastScore } = this.props;
        const date = lastScore.date;
        const score = Number(String(("0." + `${lastScore.score * 10}`)))
        return (

            <Translation>
                {
                    t =>
                        <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-6 shadow-lg rounded-lg">
                            <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative w-full max-w-full flex-grow flex-1">
                                        <h6 className="uppercase text-white mb-1 text-xs font-semibold">
                                            {t('overview')}
                                        </h6>
                                        <h2 className="text-white text-xl font-semibold">
                                            {t('last-perf-score')} <MainScorePopover />
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4">
                                {/* Chart */}
                                {
                                    lastScore.score !== undefined ?
                                        <div className="relative xl:h-350-px flex flex-wrap items-center">
                                            <div className="w-full" >
                                                <GaugeChart
                                                    id="gauge-chart5"
                                                    nrOfLevels={420}
                                                    arcsLength={[0.2, 0.5, 0.3]}
                                                    colors={['#EA4228', '#F5CD19', '#5BE12C']}
                                                    percent={score}
                                                    arcPadding={0.02}
                                                // needleColor="white"
                                                // needleBaseColor="red" 
                                                // textColor="white"
                                                />
                                            </div>
                                            <div className="w-full p-2 text-white text-center ">
                                                <h5 className="text-md ml-2">{t('last-analysis-time')}</h5> <p className="text-center  text-lg"><Moment format="HH:mm, DD/MM/YYYY" className="ml-3">{date}</Moment></p>
                                            </div>
                                        </div> :
                                        <div className="bg-white p-4 rounded-lg">
                                            <Loading className="text-white" />
                                        </div>
                                }
                            </div>
                        </div>
                }
            </Translation>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        lastScore: state.analysisLast
    }
}
export default connect(mapStateToProps, { getLastAll })(MainLastScore);