import axios from 'axios';
import { API } from './api';
import {
    GETALLNO,
    GETALLWEEKLY,
    GETLASTALL,
    GETTANKSTATUS,
} from './types';

export const getAllNo = (no) => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/a/get/${no}/all?token=${token}`);
            dispatch({
                type: GETALLNO,
                payload: response.data
            })
        }
    }
}
export const getAllWeekly = () => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        const nowDate = new Date();
        const pastDate = nowDate.getDate() - 7;
        nowDate.setDate(pastDate);
        const day = String(nowDate.getDate());
        const year = String(nowDate.getFullYear());
        const month = String(nowDate.getMonth() + 1);
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/a/Get2Dates/${year}/${month}/${day}?token=${token}`);
            dispatch({
                type: GETALLWEEKLY,
                payload: response.data
            })
        }
    }
}
export const getLastAll = () => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/a/get/last?token=${token}`);
            dispatch({
                type: GETLASTALL,
                payload: response.data
            })
        }
    }
}


export const getTankStatus = () => {
    return async dispatch => {
        const user = JSON.parse(localStorage.getItem("userToken"));
        const token = user.token;
        if (typeof token === 'string') {
            const response = await axios.get(`${API}/v1/t/getall?token=${token}`);
            dispatch({
                type: GETTANKSTATUS,
                payload: response.data
            })
        }
    }
}
