import React, { Component } from 'react';
import CardTable from 'components/Cards/CardTable';
import { Translation } from "react-i18next"
import { connect } from 'react-redux';
import {
    getTwoDateDosage,
    getAllWeeklyDosage
} from 'actions/';
import Pagination from 'components/Pagination/Pagination';


class Chem3AllTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date1: "",
            date2: "",
            emptyAlert: false,

            currentPage: 1,
            perPage: 10,
            upperPageBound: 10,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3
        }
    }
    componentDidMount() {
        this.props.getAllWeeklyDosage(14, "K3");
    }
    onChangeDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    newDate = (tarih) => {
        const newTarih = new Date(tarih);
        const dd = String(newTarih.getDate()).padStart(2, '0');
        const mm = String(newTarih.getMonth() + 1).padStart(2, '0');
        const yyyy = newTarih.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    }
    nowDate = () => {
        const now = new Date();
        const dd = String(now.getDate()).padStart(2, '0');
        const mm = String(now.getMonth() + 1).padStart(2, '0');
        const yyyy = now.getFullYear();
        return (`${yyyy}-${mm}-${dd}`);
    }
    minDateFunc = () => {
        if (this.state.date1 !== "") {
            return this.newDate(this.state.date1)
        }
        return "2000-01-01";
    }
    maxDateFunc = () => {
        if (this.state.date2 !== "") {
            return this.newDate(this.state.date2);
        }
        return this.nowDate()
    }
    onSubmitDate = () => {
        if (this.state.date1 !== "" && this.state.date2 !== "") {
            this.setState({
                emptyAlert: false,
                currentPage: 1,
            });
            const date1 = new Date(this.state.date1);
            const date2 = new Date(this.state.date2);
            this.props.getTwoDateDosage(date1, date2, "K3");
        } else {
            this.setState({
                emptyAlert: true
            });
            setTimeout(() => {
                this.setState({
                    emptyAlert: false
                })
            }, 5000);
        }
    }
    refreshComp = () => {
        this.props.getAllWeeklyDosage(14, "K3");
        this.setState({
            date1: "",
            date2: "",
            currentPage: 1,
        });
    }
    render() {
        const { chem3All } = this.props;
        const color = "light";
        const { currentPage, perPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;
        const indexOfLast = currentPage * perPage;
        const indexOfFirst = indexOfLast - perPage;
        const currentValue = chem3All.slice(indexOfFirst, indexOfLast);
        const handleClick = (event) => {
            let listid = Number(event.target.id);
            this.setState({
                currentPage: listid
            });
            setPrevAndNextBtnClass(listid);
        }
        const setPrevAndNextBtnClass = (listid) => {
            let totalPage = Math.ceil(chem3All.length / perPage);
            this.setState({ isNextBtnActive: 'disabled' });
            this.setState({ isPrevBtnActive: 'disabled' });
            if (totalPage === listid && totalPage > 1) {
                this.setState({ isPrevBtnActive: '' });
            }
            else if (listid === 1 && totalPage > 1) {
                this.setState({ isNextBtnActive: '' });
            }
            else if (totalPage > 1) {
                this.setState({ isNextBtnActive: '' });
                this.setState({ isPrevBtnActive: '' });
            }
        }
        const btnIncrementClick = () => {
            this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
            let listid = this.state.upperPageBound + 1;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        const btnDecrementClick = () => {
            this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
            let listid = this.state.upperPageBound - this.state.pageBound;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        const btnPrevClick = () => {
            if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
                this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
                this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
            }
            let listid = this.state.currentPage - 1;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        const btnNextClick = () => {
            if ((this.state.currentPage + 1) > this.state.upperPageBound) {
                this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
                this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
            }
            let listid = this.state.currentPage + 1;
            this.setState({ currentPage: listid });
            setPrevAndNextBtnClass(listid);
        }
        return (
            <div className="flex flex-wrap">
                <div className="w-full p-4">
                    <div
                        className={
                            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg " +
                            (color === "light" ? "bg-white" : "bg-blue-900 text-white")
                        }
                        id="tablo"
                    >
                        <div className="rounded-t mb-0 px-2 py-3 border-0">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full p-4 max-w-full flex-grow flex-1">
                                    <Translation>
                                        {
                                            (t) =>
                                                <h3
                                                    className={
                                                        "font-semibold text-lg " +
                                                        (color === "light" ? "text-gray-800" : "text-white")
                                                    }
                                                >
                                                    {t('analysis-results')}
                                                </h3>
                                        }
                                    </Translation>
                                </div>
                                <div className="flex flex-wrap items-center mr-4">
                                    <div className="p-4"> <input type="date" max={this.maxDateFunc()} value={this.state.date1} name="date1" id="date1" onChange={this.onChangeDate} className="border rounded-lg p-1" /></div>
                                    <div className="p-4"> <i className="fas fa-arrow-right"></i></div>
                                    <div className="p-4"> <input type="date" min={this.minDateFunc()} max={this.nowDate()} value={this.state.date2} name="date2" onChange={this.onChangeDate} className="border rounded-lg p-1" /></div>
                                    <div className="p-4"> <button onClick={this.onSubmitDate}><i className="fas fa-search"></i></button></div>
                                    <div className="p-4"> <button onClick={this.refreshComp}><i className="text-black fas fa-sync-alt"></i></button></div>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className=
                                    {
                                        this.state.emptyAlert ?
                                            "block text-white text-center mt-2 align-middle p-4 border-0 rounded-lg relative w-full bg-red-500"
                                            : "hidden"
                                    }
                                >
                                    <span className="text-xl inline-block mr-4 align-middle">
                                        <i className="fas fa-bell" />
                                    </span>
                                    <Translation>
                                        {
                                            (t) =>
                                                <span className="inline-block align-middle mr-8">
                                                    {t("date-alert")}
                                                </span>
                                        }
                                    </Translation>
                                    <button onClick={() => this.setState({ emptyAlert: false })} className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-4 outline-none focus:outline-none">
                                        <span>×</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <CardTable
                            valuesArray={currentValue}
                            page="K3"
                        />
                        <Pagination
                            perValue={perPage}
                            totalValues={chem3All.length}
                            upperPageBound={upperPageBound}
                            lowerPageBound={lowerPageBound}
                            isPrevBtnActive={isPrevBtnActive}
                            isNextBtnActive={isNextBtnActive}
                            currentPage={currentPage}
                            handleClick={handleClick}
                            btnNextClick={btnNextClick}
                            btnPrevClick={btnPrevClick}
                            btnDecrementClick={btnDecrementClick}
                            btnIncrementClick={btnIncrementClick}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        chem3All: state.chem3All
    }
}

export default connect(mapStateToProps, { getTwoDateDosage, getAllWeeklyDosage })(Chem3AllTable);