import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTankStatus } from 'actions/';
import CardSocialTraffic from 'components/Cards/CardSocialTraffic';
class TankStatus extends Component {
    componentDidMount() {
        this.props.getTankStatus();
    }

    render() {
        const { tankStatus } = this.props;
        return (
            <CardSocialTraffic
                tank={tankStatus}
            />
        )

    }
}
const mapStateToProps = (state) => {
    return {
        tankStatus: state.tankStatus
    }
}
export default connect(mapStateToProps, { getTankStatus })(TankStatus);