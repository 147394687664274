
import React, { Component } from 'react'
import { Translation } from 'react-i18next';
import Moment from 'react-moment';
import TableDropdown from 'components/Dropdowns/TableDropdown';
import Loading from 'components/Loading/Loading';
class CardTable extends Component {

  scoreBG = (value) => {
    if (Math.ceil(value) >= 70) {
      return ("shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500")
    } else {
      return ("shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500")
    }
  }
  statusColor = (bool) => {
    if (bool) {
      return (
        <Translation>
          {
            (t) =>
              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
                <i className="fas fa-circle text-green-500 mr-2"></i> {t('good')}
              </td>
          }
        </Translation>
      )
    } else {
      return (
        <Translation>
          {
            (t) =>
              <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
                <i className="fas fa-circle text-red-500 mr-2"></i> {t('bad')}
              </td>
          }
        </Translation>
      )
    }
  }

  ondalik = (value, page) => {
    switch (page) {
      case "ph":
        return value.toFixed(1)
      case "ec":
        return value.toFixed(0) + " mV"
      case "tds":
        return value.toFixed(0)
      case "orp":
        return value.toFixed(0) + " mV"
      case "do":
        return value.toFixed(0)
      case "temp":
        return value.toFixed(0) + " °C"
      case "K1":
        return value
      case "K2":
        return value
      case "K3":
        return value
      default:
        break;
    }
  }
  render() {
    const color = "light";
    const { valuesArray, page } = this.props;
    return (
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse" >
          <Translation>
            {
              (t) =>
                <thead >
                  <tr key={"as123asdfw34534sdf"}>

                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-gray-100 text-gray-600 border-gray-200"
                          : "bg-blue-800 text-blue-300 border-blue-700")
                      }
                    >
                      {t('no-time')}
                    </th>
                    <th
                      className={
                        "px-4 align-left border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-gray-100 text-gray-600 border-gray-200"
                          : "bg-blue-800 text-blue-300 border-blue-700")
                      }
                    >
                      {t('value')}
                    </th>
                    <th
                      className={
                        "px-4 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-gray-100 text-gray-600 border-gray-200"
                          : "bg-blue-800 text-blue-300 border-blue-700")
                      }
                    >
                      {t('status')}
                    </th>
                    <th
                      className={
                        "px-4 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-gray-100 text-gray-600 border-gray-200"
                          : "bg-blue-800 text-blue-300 border-blue-700")
                      }
                    >
                      {t('perf-score')}
                    </th>
                    <th
                      className={
                        "px-4 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-gray-100 text-gray-600 border-gray-200"
                          : "bg-blue-800 text-blue-300 border-blue-700")
                      }
                    ></th>
                  </tr>
                </thead>
            }
          </Translation>
          <tbody>
            {
              valuesArray.length !== 0 ?
                valuesArray.map(value => {
                  return (
                    <tr key={value.no === undefined ? value.no2 : value.no}>
                      <th className="border-t-0  align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4 text-left flex items-center">
                        {/* <span
                          className="px-2 py-1  rounded-lg bg-blue-200 text-black-900"
                        >{value.no}</span> */}
                        <span
                          className="ml-3 text-gray-700 rounded-lg bg-blue-200 px-2 py-1 "
                        >
                          <Moment format="HH:mm, DD/MM/YYYY">
                            {value.date}
                          </Moment>

                        </span>
                      </th>
                      <td className="border-t-0  font-bold text-md align-left border-l-0 border-r-0 whitespace-no-wrap p-4">
                        {value[page] !== undefined ? this.ondalik(value[page], page) : "--"}
                      </td>
                      {
                        value.action_required === undefined ?
                          this.statusColor(value.job.status) :
                          this.statusColor(!value.action_required)
                      }
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
                        <div className="flex items-center">
                          <span className="w-16 text-left">{value.score}</span>
                          <div className="relative ml-auto w-full">
                            <div className={
                              "overflow-hidden h-2 text-sm flex rounded bg-gray-200"
                            }>
                              <div
                                style={{ width: value.score + "%" }}
                                className={
                                  this.scoreBG(value.score)
                                }
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4 text-right">
                        <TableDropdown
                          analysisId={value.no === undefined ? value.no2 : value.no}
                          dosage={value.no2 !== undefined ? true : false}
                        />
                      </td>
                    </tr>
                  )
                }) :
                <tr >
                  <th className="p-4">
                    <Loading />
                  </th>
                  <td className="p-4">
                    <Loading />
                  </td>
                  <td className="p-4">
                    <Loading />
                  </td>
                  <td className="p-4">
                    <Loading />
                  </td>
                </tr>
            }
          </tbody>
        </table>
      </div>
    )
  }
}
export default CardTable;