import React from "react";
import { connect } from 'react-redux';
import { logout } from 'actions/';
import { createPopper } from "@popperjs/core";
import avatar from 'assets/img/team-1-800x800.jpg';
import H2OMonitor from 'assets/img/h2omon_logo.png';
const UserDropdown = (props) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-gray-600 block cursor-pointer"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-white inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border shadow-2xl"
              src={H2OMonitor}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-2xl min-w-48"
        }
      >
        <a
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          Kullanıcı Bilgileri
        </a>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <a
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          Gemi Bilgileri
        </a>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <a
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          Sistem Bilgileri
        </a>
        <div className="h-0  my-2 border border-solid border-gray-200" />
        <a
          className={
            "text-sm py-2 px-4 cursor-pointer font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => {
            props.logout();
            e.preventDefault();
          }}
        >
          Çıkış
        </a>
        {/* <div className="h-0 my-2 border border-solid border-gray-200" /> */}
        {/* <a
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          Seprated link
        </a> */}
      </div>
    </>
  );
};

export default connect(null, { logout })(UserDropdown);
