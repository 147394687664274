import React, { Component } from 'react'
import { connect } from 'react-redux';
import { isLogged } from 'actions/authAction';
import TempTable from './TempTable';
import TempGraphic from './TempGraphic';
class TempPage extends Component {
    componentDidMount() {
        this.props.isLogged(this.props.history);
    }
    render() {
        return (
            <>
                <div className="flex flex-wrap">
                    <div className="w-full p-4 mb-0">
                        <TempGraphic />
                    </div>
                </div>
                <TempTable />
            </>
        )
    }
}
export default connect(null, { isLogged })(TempPage);