import { combineReducers } from 'redux';

import auth from './authReducer';
import phAll from './phReducers/phReducers';
import phLast from './phReducers/phLastReducer';
import phMavg from './phReducers/phMavgReducer';
import perfLast from './perfReducers/perfLastReducer';
import perfMavg from './perfReducers/perfMavgReducer';
import analysisNoAll from './analysisReducers/analysisNoReducer'
import tempLast from './tempReducers/tempLastReducer';
import tempMavg from './tempReducers/tempMavgReducer';
import tempAll from './tempReducers/tempReducer';
import twoDate from './analysisReducers/analysisTwoDateReducer'
import weekly from './analysisReducers/analysisWeeklyReducer';
import ecLast from './condReducers/condLastReducer';
import ecMavg from './condReducers/condMavgReducer';
import ecAll from './condReducers/condReducer';
import tdsAll from './tdsReducers/tdsReducer';
import tdsLast from './tdsReducers/tdsLastReducer';
import tdsMavg from './tdsReducers/tdsMavgReducer';
import orpAll from './orpReducers/orpReducer';
import orpLast from './orpReducers/orpLastReducer';
import orpMavg from './orpReducers/orpMavgReducer';
import doAll from './oxygenReducers/oxyReducer';
import doLast from './oxygenReducers/oxyLastReducer';
import doMavg from './oxygenReducers/oxyMavgReducer';
import analysisLast from './analysisReducers/lastAnalysisReducer';
import tankStatus from './analysisReducers/tankStatusReducer';
import lastDosages from './dosageReducers/lastFiveDosageReducer';
import chem1Last from './chem1Reducers/chem1LastReducer';
import chem2Last from './chem2Reducers/chem2LastReducer';
import chem3Last from './chem3Reducers/chem3LastReducer';
import chem1Mavg from './chem1Reducers/chem1MavgReducer';
import chem2Mavg from './chem2Reducers/chem2MavgReducer';
import chem3Mavg from './chem3Reducers/chem3MavgReducer';
import weeklyDosage from './dosageReducers/dosageWeeklyReducer';
import twoWeeklyDosage from './dosageReducers/dosageTwoWeeklyReducer';
import dosageNoAll from './dosageReducers/dosageNoReducer';
import twoDateDosage from './dosageReducers/dosageTwoDateReducer';
import chem1All from './chem1Reducers/chem1Reducer';
import chem2All from './chem2Reducers/chem2Reducer';
import chem3All from './chem3Reducers/chem3Reducer';


const rootReducer = combineReducers({
  auth,
  phAll,
  phLast,
  phMavg,
  perfLast,
  perfMavg,
  analysisNoAll,
  tempLast,
  tempMavg,
  tempAll,
  twoDate,
  weekly,
  ecAll,
  ecLast,
  ecMavg,
  tdsAll,
  tdsLast,
  tdsMavg,
  orpAll,
  orpLast,
  orpMavg,
  doAll,
  doLast,
  doMavg,
  analysisLast,
  tankStatus,
  lastDosages,
  chem1Last,
  chem2Last,
  chem3Last,
  chem1Mavg,
  chem2Mavg,
  chem3Mavg,
  weeklyDosage,
  twoWeeklyDosage,
  dosageNoAll,
  twoDateDosage,
  chem1All,
  chem2All,
  chem3All
});

export default rootReducer;