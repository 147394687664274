import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAllWeeklyDosage } from 'actions/'
import ChartLine from 'components/Cards/ChartLine';

class Chem1Graphic extends Component {
    componentDidMount() {
        this.props.getAllWeeklyDosage(7, "K1");
    }
    render() {
        const { weeklyDosage } = this.props;

        return (
            <>
                <ChartLine
                    data={weeklyDosage}
                    page={"K1"}
                />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        weeklyDosage: state.weeklyDosage
    }
}
export default connect(mapStateToProps, { getAllWeeklyDosage })(Chem1Graphic);